import styles from './Workspace.module.scss';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DisplayableTreeProperty } from '../../tree/Tree';
import { FilteredPartialTree } from '../../tree/FilteredPartialTree';
import { usePropertyConfigurations } from '../../properties/usePropertyConfigurations';
import Spinner from '../../components/UI/Spinner/Spinner';
import { Flippers } from '../../switches/Flippers';
import { ArrowLeft, ArrowRight, ArrowUp, Download, PlusCircle, StatsReport } from 'iconoir-react';
import { useCurrentAccount } from '../../account/useAccounts';
import TableDashboard from './components/TableDashboard';
import { TFunction, useTranslation } from 'react-i18next';
import { SortingDirection } from '../../components/UI/SortButton/SortButton';
import { useFetchTreeList } from './useFetchTreeList';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import LazyTreeList from './LazyTreeList';
import FileDownloadButton, { downloadBlob } from './components/FileDownloadButton';
import Button from '../../components/UI/Button/Button';
import FloatingBar from '../../components/UI/FloatingBar/FloatingBar';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../components/UI/Toast/DismissibleToast';
import { TaskManagerView } from '../TaskManager/TaskManagerView';
import { useNavigate } from 'react-router-dom';
import { TaskTemplateWithTrees } from '../TaskManager/create/TaskTemplate';
import { ContextMenu } from '../../components/UI/ContextMenu/ContextMenu';
import { ContextMenuTrigger } from '../../components/UI/ContextMenu/ContextMenuTrigger';
import { ContextMenuContent } from '../../components/UI/ContextMenu/ContextMenuContent';
import { FunctionButton } from '../../components/UI/Button/LegacyButton';
import { useTracking } from '../../analytics/useTracking';

export enum TreeTableVersion {
  TASK_CREATION,
  INSIGHTS
}

export default function TreeTable(props: TreeTableProps) {
  const propertyConfigs = usePropertyConfigurations();
  const account = useCurrentAccount();
  const { t } = useTranslation();
  const { urlContext, taskManagerService, treeService } = useContext(DependencyInjectionContext);
  const organization = account.organization;
  const navigate = useNavigate();
  const { events, track } = useTracking();
  const reverseMASelection = urlContext.getReverseMASelection();
  const [creating, setCreating] = useState(false);

  const [sort, setSort] = useState<string | null>(DisplayableTreeProperty.ExternalId);
  const { treeList, firstPageIsLoading } = useFetchTreeList(0, sort, props.filters, props.properties, props.taskTemplate?.id);

  const [tableAnimations, setTableAnimations] = useState<{ treeId: string, promise: Promise<void> }[]>([]);
  const [visibleTableFragment, setVisibleTableFragment] = useState<{ trees: { tree: FilteredPartialTree }[], offset: number }>({ trees: [], offset: 0 });
  const [currentView, setCurrentView] = useState({ from: 0, to: 50 });
  const [columnSettings, setColumnSettings] = useState(
    new Map<Property, ColumnSettings>(props.properties.map((property): [Property, ColumnSettings] => [
      property,
      {
        sort: property === DisplayableTreeProperty.ExternalId ? SortingDirection.ASCENDING : null,
        aggregateFunction: getAggregationOptions(t, getColumnConfiguration(property))[0]?.value ?? null }
    ]))
  );
  const [selectedTreeIds, setSelectedTreeIds] = useState<string[]>([]);
  const [allTreeIds, setAllTreeIds] = useState<string[]>([]);

  const treeListTableRef = useRef<HTMLDivElement | null>(null);
  const tableBodyContainerRef = useRef<HTMLDivElement | null>(null);
  const fullSizedContainerRef = useRef<HTMLDivElement | null>(null);

  const total = treeList?.getTotal() ?? 0;
  const notFilteredCount = treeList?.getNotFilteredCount();

  const scrolledToLeft = treeListTableRef.current?.scrollLeft === 0;
  const scrolledToRight = (treeListTableRef.current?.clientWidth || 0) + (treeListTableRef.current?.scrollLeft || 0) >=
    (fullSizedContainerRef.current?.clientWidth || 0);

  const scrollToTableTop = () => treeListTableRef.current?.scrollTo({ top: 0, behavior: 'auto' });

  const useEffectDependencyForColumnSettingInitialization = props.properties.join('');

  useEffect(() => {
    setColumnSettings(
      columnSettings =>
        new Map<Property, ColumnSettings>([
          ['externalId', { sort: SortingDirection.ASCENDING, aggregateFunction: null }],
          ...props.properties.map((property): [Property, ColumnSettings] => [
            property,
            columnSettings.get(property) ?? {
              sort: null,
              aggregateFunction: getAggregationOptions(t, getColumnConfiguration(property))[0]?.value ?? null
            }
          ])
        ])
    );
  }, [useEffectDependencyForColumnSettingInitialization]);

  useEffect(() => {
    if (!treeList) return;
    treeList.getAll().then(trees => trees.map(it => it.id)).then(treeIds => {
      setAllTreeIds(treeIds);
      setSelectedTreeIds(treeIds);
    });
    return () => treeList?.cancel();
  }, [treeList]);

  useEffect(() => {
    if (
      !treeListTableRef.current || !tableBodyContainerRef.current || !fullSizedContainerRef.current || total === 0
    ) return;

    const scrollContainer = treeListTableRef.current!;

    fullSizedContainerRef.current.style.height = ((notFilteredCount || 1) * props.ROW_HEIGHT).toString() + 'px';
    const maxVisibleRows = Math.floor((scrollContainer.offsetHeight - props.HEADER_HEIGHT) / props.ROW_HEIGHT);
    setCurrentView({ from: 0, to: maxVisibleRows });

    scrollContainer.scrollTo({ top: 0 });

    const onScroll = () => {
      const from = Math.floor((scrollContainer.scrollTop) / props.ROW_HEIGHT);
      const delta = Math.floor((scrollContainer.offsetHeight - props.HEADER_HEIGHT) / props.ROW_HEIGHT);
      const to = from + delta;
      setCurrentView({ from, to });
    };

    scrollContainer.addEventListener('scroll', onScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', onScroll);
    };
  }, [treeListTableRef, total, props.open, notFilteredCount]);

  useEffect(() => {
    (async () => {
      const from = Math.max(currentView.from - 10, 0);
      const to = currentView.to + 10;

      await Promise.all(tableAnimations.map(it => it.promise));
      if (!treeList || !tableBodyContainerRef.current || !treeListTableRef.current) return;
      const trees = await treeList.getRange(from, to);
      setVisibleTableFragment({ trees: trees.map(it => ({ tree: it })), offset: from });
      const offset = tableAnimations.length;
      if (treeListTableRef.current?.scrollTop > 0) {
        treeListTableRef.current.scrollTop = (treeListTableRef.current?.scrollTop) + offset * 40;
      }
      setTableAnimations([]);
    })();
  }, [treeList, currentView]);

  useEffect(() => {
    (async () => {
      if (!treeList) return;
      if (props.selectedTreeId && props.open) {
        const index = await treeList?.findIndexById(props.selectedTreeId);
        const numberOfVisibleRows = (treeListTableRef.current!.offsetHeight - props.HEADER_HEIGHT) / props.ROW_HEIGHT;
        if (tableAnimations.length === 0) {
          treeListTableRef.current!.scrollTop = Math.max(index - (numberOfVisibleRows - 0.5) / 2, 0) * props.ROW_HEIGHT;
        }
      }
    })();
  }, [treeList, props.selectedTreeId, props.open]);

  const handleSelectTree = (event, id) => {
    setSelectedTreeIds(prev => {
      if (prev.includes(id)) {
        return prev.filter(it => it !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  const handleSelectAll = () => {
    if (selectedTreeIds.length === allTreeIds.length) {
      setSelectedTreeIds([]);
    } else {
      setSelectedTreeIds(allTreeIds);
    }
  };

  const createTask = async () => {
    setCreating(true);
    if (!treeList) return;
    const managedAreaIds = urlContext.getManagedAreaIds();

    const responseStatus = await taskManagerService.createTask({
      organizationId: organization.id,
      managedAreaIds,
      reverseMASelection,
      type: props.taskTemplate!.name,
      treeIds: selectedTreeIds,
      connectedModule: props.taskTemplate!.connectedModule,
      jobColumnName: props.taskTemplate!.jobColumnName
    });

    if (responseStatus === 201) {
      dismissibleSuccessToast(t('taskManager.taskCreatedSuccessfully'));
      navigate(`/organizations/${organization.id}/task-manager/${TaskManagerView.MANAGE}`);
    } else {
      dismissibleErrorToast(t('taskManager.taskCreationFailed'));
    }
    setCreating(false);
  };

  const generateReport = () => {
    urlContext.setReportOpen(true);
    track(events.REPORT_GENERATED_IN_TABLE);
  };

  const handleShpExport = async e => {
    e.stopPropagation();
    const response = await treeService.getShpExport(organization.id, urlContext.getReverseMASelection(), urlContext.getManagedAreaIds(), urlContext.getTreeFilterIds(), urlContext.getVisibleTableProperties() || []);
    const contentType = response.headers['content-type'];
    const fileName = response.headers['content-disposition'].match(/filename="([^"]*)"/)?.[1] || 'trees';

    downloadBlob(response.data, contentType, fileName);
  };

  if (propertyConfigs.isLoading) return <Spinner />;
  return <>
    {props.open && (
      <div
        className={`${styles.treeListTableContainer} bg-outer-space-800`}
        onMouseEnter={() => {
          if (props.properties.length * 200 + 100 > window.innerWidth) {
            const arrows = document.getElementsByClassName(styles.floatingArrow);
            for (let i = 0; i < arrows.length; i++) {
              const arrow = arrows.item(i);
              (arrow as HTMLDivElement).style.visibility = 'visible';
            }
          }
        }}
        onMouseLeave={() => {
          const arrows = document.getElementsByClassName(styles.floatingArrow);
          for (let i = 0; i < arrows.length; i++) {
            const arrow = arrows.item(i);
            (arrow as HTMLDivElement).style.visibility = 'hidden';
          }
        }}
      >
        <TableDashboard
          treeList={treeList as LazyTreeList}
          treeTableVersion={props.treeTableVersion}
        />
        {account.organization.isEnabled(Flippers.horizontalPaginationInTable) && <>
          <div
            className={`${styles.floatingArrow} ${styles.left}`}
            style={{ display: (scrolledToLeft || treeList?.getTotal() === 0) ? 'none' : 'block' }}
            onClick={() => {
              treeListTableRef.current?.scroll({ left: treeListTableRef.current?.scrollLeft - 200 });
            }}
          >
            <ArrowLeft style={{ margin: '2px' }} />
          </div>
          <div
            className={`${styles.floatingArrow} ${styles.right}`}
            style={{ display: (scrolledToRight || treeList?.getTotal() === 0) ? 'none' : 'block' }}
            onClick={() => {
              treeListTableRef.current?.scroll({ left: treeListTableRef.current?.scrollLeft + 200 });
            }}
          >
            <ArrowRight style={{ margin: '2px' }} />
          </div>
        </>}
        <div
          className={`rounded-t-lg w-fit max-w-[calc(100%-3rem)] overflow-hidden mx-6 shadow-lg
            ${props.treeTableVersion === TreeTableVersion.INSIGHTS ? 'h-[calc(100%-92px)]' : ''}
            ${props.treeTableVersion === TreeTableVersion.TASK_CREATION ? 'h-[calc(100%-90px)]' : ''}
          `}
        >
          <div
            className={`${styles.treeListTable} max-w-full`}
            ref={treeListTableRef}
          >
            {props.areaFilterIsSelected ?
              <>
                <TableHeader
                  columnSettings={columnSettings}
                  properties={props.properties}
                  onSortDirectionChange={setSort}
                  setColumnSettings={setColumnSettings}
                  managedAreas={treeList?.getManagedAreas() || []}
                  treeTableVersion={props.treeTableVersion}
                  handleSelectAll={handleSelectAll}
                  allSelected={selectedTreeIds.length === allTreeIds.length}
                />
                <div ref={fullSizedContainerRef}>
                  <div
                    ref={tableBodyContainerRef}
                    style={{ position: 'relative', top: (visibleTableFragment.offset * props.ROW_HEIGHT) + 'px' }}
                  >
                    <div className={styles.body}>
                      {visibleTableFragment.trees.map((it, index) => {
                        const treeId = it.tree.id;
                        return <TableRow
                          tree={it.tree}
                          selectedTreeId={props.selectedTreeId}
                          index={index}
                          key={`tree-${index}`}
                          properties={props.properties}
                          onSelect={() => props.onSelect(treeId === props.selectedTreeId ? '' : treeId)}
                          windSpeed={props.windSpeed}
                          selectedTreeProperty={props.selectedTreeProperty}
                          propertyConfigs={propertyConfigs.data!}
                          showCheckboxes={props.treeTableVersion === TreeTableVersion.TASK_CREATION}
                          handleTreeSelection={handleSelectTree}
                          isSelected={selectedTreeIds.includes(treeId)}
                        />;
                      })}
                    </div>
                  </div>
                </div>
              </>
              : <></>
            }
          </div>
        </div>
        {props.areaFilterIsSelected && (treeListTableRef.current?.scrollTop ?? 0) > 0 && <button className={styles.jumperButton} onClick={() => scrollToTableTop()}>
          <ArrowUp/></button>}
        {!props.areaFilterIsSelected && <EmptyState/>}
        {firstPageIsLoading && <Spinner/>}
        <FloatingBar>
          <div className="flex items-center gap-4 text-sm">
            <div className="text-nowrap flex gap-1.5 items-center">
              <span className="font-bold text-xl">{
                props.treeTableVersion === TreeTableVersion.INSIGHTS ?
                  treeList?.getNotFilteredCount() || 0
                  :
                  selectedTreeIds.length
              }</span>{t('treeList.selected')}
            </div>
            {props.treeTableVersion === TreeTableVersion.TASK_CREATION && (
              <Button
                onClick={createTask}
                disabled={creating}
              >
                <div className="flex text-nowrap items-center gap-2">
                  <PlusCircle height={20} width={20} />
                  {t('treeList.createTask')}
                </div>
              </Button>
            )}
            <ContextMenu>
              <ContextMenuTrigger
                className="flex text-nowrap items-center gap-2 flex-row px-3 py-1.5 border border-outer-space-500 rounded-[50px]">
                <Download className="size-6" />
                {t('treeList.downloadData')}
              </ContextMenuTrigger>
              <ContextMenuContent className="rounded-lg text-greehill-00 border border-outer-space-400 text-sm">
                {treeList && (
                  <FileDownloadButton
                    header={props.properties}
                    treesProvider={() => treeList.getAll()}
                    windSpeed={props.windSpeed}
                  >
                    <FunctionButton className="w-full py-1.5 items-start">
                      {t('treeList.viewDownloadDropdown.downloadAll')}
                    </FunctionButton>
                  </FileDownloadButton>
                )}
                <FunctionButton
                  onClick={handleShpExport}
                  className="w-full py-1.5 items-start"
                >
                  {t('treeList.viewDownloadDropdown.downloadAllShp')}
                </FunctionButton>
              </ContextMenuContent>
            </ContextMenu>
            <FunctionButton variant="rounded" onClick={generateReport}>
              <div className="flex items-center gap-2 flex-row">
                <StatsReport className="size-5" />
                {t('reporting.generateReport')}
              </div>
            </FunctionButton>
          </div>
        </FloatingBar>
      </div>
    )}
  </>;
}

function EmptyState() {
  const { t } = useTranslation();

  return <div className={styles.emptyState}>
    <h2>{t('workspace.emptyState')}</h2>
    <p>{/*Lorem ipsum*/}</p>
  </div>;
}
export function getAggregationOptions(t: TFunction, configuration: ColumnConfiguration) {
  return [
    { label: t('treeList.min'), value: 'min' as const },
    { label: t('treeList.max'), value: 'max' as const },
    { label: t('treeList.sum'), value: 'sum' as const },
    { label: t('treeList.avg'), value: 'avg' as const },
    { label: t('treeList.med'), value: 'med' as const }
  ].filter(it => configuration[it.value]);
}

export type Property = DisplayableTreeProperty | 'externalId';

export interface ColumnConfiguration {
  min: boolean,
  max: boolean,
  avg: boolean,
  med: boolean,
  sort: boolean,
  sum: boolean,
  graph: boolean
}

export const defaultColumnConfiguration: ColumnConfiguration = {
  min: false,
  max: false,
  avg: false,
  med: false,
  sort: false,
  sum: false,
  graph: false
};

const numericColumnConfiguration: ColumnConfiguration = { min: true, max: true, avg: true, med: true, sort: true, sum: false, graph: true };
const enumColumnConfiguration: ColumnConfiguration = { min: false, max: false, avg: false, med: false, sort: true, sum: false, graph: true };

export const columnConfiguration = new Map<Property, ColumnConfiguration>([
  ['externalId', defaultColumnConfiguration],
  [DisplayableTreeProperty.ScientificName, enumColumnConfiguration],
  [DisplayableTreeProperty.Species, enumColumnConfiguration],
  [DisplayableTreeProperty.Genus, enumColumnConfiguration],
  [DisplayableTreeProperty.ManagedAreaId, enumColumnConfiguration],
  [DisplayableTreeProperty.Status, enumColumnConfiguration],
  [DisplayableTreeProperty.VitalityVigor, enumColumnConfiguration],
  [DisplayableTreeProperty.Height, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkHeight, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyHeight, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyWidth, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkCircumference, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkDiameter, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyCircumference, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactorAt80Kmh, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactors, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafArea, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafBiomass, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafAreaIndex, numericColumnConfiguration],
  [DisplayableTreeProperty.CarbonStorage, numericColumnConfiguration],
  [DisplayableTreeProperty.GrossCarbonSequestration, numericColumnConfiguration],
  [DisplayableTreeProperty.NO2, numericColumnConfiguration],
  [DisplayableTreeProperty.SO2, numericColumnConfiguration],
  [DisplayableTreeProperty.PM25, numericColumnConfiguration],
  [DisplayableTreeProperty.CO, numericColumnConfiguration],
  [DisplayableTreeProperty.O3, numericColumnConfiguration],
  [DisplayableTreeProperty.NDVI, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeHealth, numericColumnConfiguration],
  [DisplayableTreeProperty.PotentialEvapotranspiration, numericColumnConfiguration],
  [DisplayableTreeProperty.Transpiration, numericColumnConfiguration],
  [DisplayableTreeProperty.OxygenProduction, numericColumnConfiguration],
  [DisplayableTreeProperty.ThermalComfort, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueRado, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueCavat, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueKoch, numericColumnConfiguration],
  [DisplayableTreeProperty.LeaningAngle, numericColumnConfiguration],
  [DisplayableTreeProperty.WaterIntercepted, numericColumnConfiguration],
  [DisplayableTreeProperty.Evaporation, numericColumnConfiguration],
  [DisplayableTreeProperty.AvoidedRunoff, numericColumnConfiguration],
  [DisplayableTreeProperty.Dieback, numericColumnConfiguration],
  [DisplayableTreeProperty.Slenderness, numericColumnConfiguration],
  [DisplayableTreeProperty.LiveCrownRatio, numericColumnConfiguration],
  [DisplayableTreeProperty.CrownLightExposure, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafAreaPerCrownVolume, numericColumnConfiguration],
  [DisplayableTreeProperty.ViStatus, enumColumnConfiguration],
  [DisplayableTreeProperty.HasMitigation, enumColumnConfiguration],
  [DisplayableTreeProperty.HasAssessmentRequest, enumColumnConfiguration],
  [DisplayableTreeProperty.HasViObservation, enumColumnConfiguration],
  [DisplayableTreeProperty.Mitigations, enumColumnConfiguration],
  [DisplayableTreeProperty.AssessmentRequests, enumColumnConfiguration],
  [DisplayableTreeProperty.ViObservations, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierHeightPerCrownVolume, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierHeightPerLeafArea, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierTrunkDiameterPerHeight, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea, enumColumnConfiguration],
  [DisplayableTreeProperty.OverallOutlierIndex, numericColumnConfiguration]
]);

export function getColumnConfiguration(property: Property) {
  return columnConfiguration.get(property) ?? defaultColumnConfiguration;
}

export interface ColumnSettings {
  sort: null | SortingDirection,
  aggregateFunction: null | 'min' | 'max' | 'avg' | 'med' | 'sum'
}

interface TreeTableProps {
  filters: TreeFilter[],
  properties: Property[],
  selectedTreeId: string | null,
  onSelect: (treeId: string) => void,
  ROW_HEIGHT: number,
  HEADER_HEIGHT: number,
  areaFilterIsSelected: boolean,
  windSpeed: number,
  selectedTreeProperty: DisplayableTreeProperty | null,
  open: boolean,
  treeTableVersion: TreeTableVersion,
  taskTemplate?: TaskTemplateWithTrees
}
