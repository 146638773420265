import styles from './GraphModal.module.scss';
import Modal from '../../../components/Modal/Modal';
import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { Xmark } from 'iconoir-react';
import PieChart from './charts/PieChart';
import { Tree } from '../../../tree/Tree';
import BarChart from './charts/BarChart';
import { useCallback, useContext, useEffect, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { usePropertyConfigurations } from '../../../properties/usePropertyConfigurations';
import PropertyColorConfiguration from '../../../properties/PropertyColorConfiguration';
import { useTranslation } from 'react-i18next';
import RadioButtonGroup from '../../../components/UI/RadioButtonGroup/RadioButtonGroup';
import barChartIcon from '../../../components/UI/Icon/svg/barchart.svg';
import barChartOutlineIcon from '../../../components/UI/Icon/svg/barchart-outline.svg';
import pieChartIcon from '../../../components/UI/Icon/svg/piechart.svg';
import pieChartOutlineIcon from '../../../components/UI/Icon/svg/piechart-outline.svg';
import treeIcon from '../../../components/UI/Icon/svg/tree.svg';
import treeOutlineIcon from '../../../components/UI/Icon/svg/tree-outline.svg';
import percentIcon from '../../../components/UI/Icon/svg/percent.svg';
import percentOutlineIcon from '../../../components/UI/Icon/svg/percent-outline.svg';
import PropertyConfiguration from '../../../properties/PropertyConfiguration';
import { getProperties } from '../../TaskManager/create/FilterConfig';

export type NumericDistributionPoint = { key: string, value: number, percentage: number };
export type LabelDistributionPoint = { key: string, value: number, percentage: number };
type EnumDistributionPoint = { key: string, value: number, percentage: number };

export type GraphDto = {
  numericDistribution: NumericDistributionPoint[] | null,
  labelDistribution: LabelDistributionPoint[] | null,
  enumDistribution: EnumDistributionPoint[] | null
};

const dataTypeItems = [
  { id: 'numericDistribution', translationKey: 'workspace.graphModal.dataTypes.numericDistribution' },
  { id: 'labelDistribution', translationKey: 'workspace.graphModal.dataTypes.labelDistribution' }
];

const chartTypeItems = [
  { id: 'barChart', icon: { filled: barChartIcon, outline: barChartOutlineIcon } },
  { id: 'pieChart', icon: { filled: pieChartIcon, outline: pieChartOutlineIcon } }
];

const valueTypeItems = [
  { id: 'count', icon: { filled: treeIcon, outline: treeOutlineIcon } },
  { id: 'percent', icon: { filled: percentIcon, outline: percentOutlineIcon } }
];

export const graphModalColors = [
  '#2D5050',
  '#4AA35F',
  '#5FCC7D',
  '#A5E148',
  '#F5BD43',
  '#E47E30',
  '#DB3E34',
  '#54B5CC',
  '#2C678B',
  '#5F7078'
];

export default function GraphModal(props: GraphModalProps) {
  const { t } = useTranslation();
  const { treeService, urlContext } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const managedAreaIds = urlContext.getManagedAreaIds();
  const fields = urlContext.getVisibleTableProperties();
  const reverseMASelection = urlContext.getReverseMASelection();
  const filterConfiguration = urlContext.getFilterConfiguration();
  const [chartData, setChartData] = useState<GraphDto | null>(null);
  const [dataType, setDataType] = useState<string>('numericDistribution');
  const [chartType, setChartType] = useState<'barChart' | 'pieChart'>('barChart');
  const [valueType, setValueType] = useState<'count' | 'percent'>('count');
  const propertyConfigs = usePropertyConfigurations();

  const getColorMap = (config?: PropertyConfiguration) => {
    if (dataType === 'numericDistribution' || !config) return graphModalColors;
    return PropertyColorConfiguration.getColorsForConfig(config).map(it => `rgb(${it})`);
  };

  const getDataTypeItems = useCallback(() => {
    return dataTypeItems.map(item => {
      if (item.id === 'labelDistribution' && !chartData?.labelDistribution?.length) {
        return { ...item, disabled: true };
      }
      return item;
    });
  }, [chartData?.labelDistribution]);

  useEffect(() => {
    (async () => {
      const fieldsToFetch = Array.from(new Set([
        ...(fields || []),
        ...getProperties(filterConfiguration),
        props.property
      ])) as (keyof Tree)[];
      const result = await treeService.fetchGraphData(
        organization.id,
        managedAreaIds,
        reverseMASelection,
        fieldsToFetch,
        filterConfiguration,
        props.taskOrTaskTemplateId,
        props.property as keyof Tree,
        Tree.isEnumProperty(props.property)
      );
      const resultSorted = {
        ...result,
        enumDistribution: sortEnumDistribution(replaceKeys(result.enumDistribution, props.property, props.managedAreas))
      };
      setChartData(resultSorted);
    })();
  }, [props.property]);

  return (
    <Modal onHide={props.onClose} isVisible={true} className={styles.modal}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t('workspace.graphModal.title')} {t(Tree.getTKeyForProperty(props.property))}</h3>
        <FunctionButton
          icon={<Xmark />}
          onClick={props.onClose}
          className={styles.closeButton}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.radioButtonRow}>
          <div className={styles.radioButtonsOnTheLeft}>
            <RadioButtonGroup options={getDataTypeItems()} value={dataType} onSelect={id => setDataType(id)}/>
            <RadioButtonGroup options={chartTypeItems} value={chartType} onSelect={id => setChartType(id as ('barChart' | 'pieChart'))}/>
          </div>
          <RadioButtonGroup options={valueTypeItems} value={valueType} onSelect={id => setValueType(id as ('count' | 'percent'))} />
        </div>
        {chartData && (
          <div className={styles.chartContainer}>
            {chartType === 'pieChart' ?
              <PieChart
                data={Tree.isEnumProperty(props.property) ? chartData.enumDistribution! : chartData[dataType]}
                displayPercentage={valueType === 'percent'}
                property={props.property}
              /> :
              <BarChart
                property={props.property}
                data={Tree.isEnumProperty(props.property) ? chartData.enumDistribution! : chartData[dataType]}
                displayPercentage={valueType === 'percent'}
                barColors={getColorMap(propertyConfigs.data.find(it => it.property === props.property))}
              />}
          </div>
        )}
      </div>
    </Modal>
  );
}

function replaceKeys(enumDistribution: EnumDistributionPoint[] | null, property: string, managedAreas: { id: string, code: string }[]): EnumDistributionPoint[] | null {
  if (!enumDistribution) return null;
  if (property === 'managedAreaId') return enumDistribution.map(it => ({
    key: managedAreas.find(area => area.id === it.key)?.code ?? it.key,
    value: it.value,
    percentage: it.percentage
  }));
  return enumDistribution;
}

function sortEnumDistribution(enumDistribution: EnumDistributionPoint[] | null): EnumDistributionPoint[] | null {
  if (!enumDistribution) return null;
  const sorted = [...enumDistribution];
  sorted.sort((a, b) => b.value - a.value);
  const rest = sorted.splice(10);
  return [...sorted, ...collectTheRest(rest)];
}

function collectTheRest(enumDistribution: EnumDistributionPoint[]): [EnumDistributionPoint] | [] {
  if (enumDistribution.length === 0) return [];

  return [{
    key: 'other',
    value: enumDistribution.reduce((acc, it) => acc + it.value, 0),
    percentage: enumDistribution.reduce((acc, it) => acc + it.percentage, 0)
  }];
}

interface GraphModalProps {
  onClose: () => void,
  property: string,
  managedAreas: { id: string, code: string }[],
  taskOrTaskTemplateId?: string
}
