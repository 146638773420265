import React, { Children, ReactElement, useContext } from 'react';

import Sidebar from './Sidebar';
import { useCurrentAccount } from '../../../../account/useAccounts';
import {
  Header,
  HeaderGlobalBar,
  HeaderName,
  Theme
} from '@carbon/react';
import greeHillLogoDark from '../../../UI/Icon/svg/greehill-logo.svg';
import greeHillLogoLight from '../../../UI/Icon/svg/greehill-logo-light.svg';
import useCarbonThemes, { CarbonTheme } from '../../Carbon/useCarbonThemes';
import ProfileMenu from './ProfileMenu';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';

export default function PageLayout(props: PageLayoutProps) {
  const { theme } = useCarbonThemes();
  const account = useCurrentAccount();
  const { urlContext } = useContext(DependencyInjectionContext);
  const isSideBarOpen = urlContext.getSidebarOpen();

  const content = Children.map(props.children, child => (child.type === Content ? child : null));

  return (
    <Theme theme={theme} className="normalize-scrollbar">
      <div style={{ backgroundColor: 'var(--cds-background)' }} className="w-[100vw] h-[100vh] fixed left-0 top-0 -z-10"/>
      <Header className="flex justify-between items-center" aria-label="header">
        <HeaderName prefix="">
          <img src={getLogoUrl(theme)} alt="greeHill" />
        </HeaderName>

        <HeaderGlobalBar>
          <ProfileMenu currentAccount={account} />
        </HeaderGlobalBar>
      </Header>
      {account.isEmployeeOrAbove() && <Sidebar/>}
      <div className={(isSideBarOpen ? 'pl-[255px]' : 'pl-12') + ' pt-12 h-[100vh] w-[100vw]'}>
        {content}
      </div>
    </Theme>
);
}

function LeftNavExtensions({ children }) {
  return children;
}

function CenterNavExtensions({ children }) {
  return children;
}

function RightNavExtensions({ children }) {
  return children;
}

PageLayout.LeftNavExtensions = LeftNavExtensions;
PageLayout.CenterNavExtensions = CenterNavExtensions;
PageLayout.RightNavExtensions = RightNavExtensions;

function Content({ children }) {
  return children;
}

PageLayout.Content = Content;

interface PageLayoutProps {
  children: ReactElement | ReactElement[],
  detailsNavbar?: boolean,
  borderless?: boolean
}

function getLogoUrl(theme: CarbonTheme) {
  return [CarbonTheme.g90, CarbonTheme.g100].includes(theme) ? greeHillLogoDark : greeHillLogoLight;
}